import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, Slider, Typography} from '@mui/material';

import {BotFormData} from '../../types';

interface TimeoutSliderFieldProps {
  form: UseFormReturn<BotFormData>;
  name: 'pipeline.maximumDuration' | 'pipeline.silenceTimeout';
  min: number;
  max: number;
  defaultValue: number;
}

function valuetext(value: number) {
  return `${value} seconds`;
}

export default function TimeoutSliderField(props: TimeoutSliderFieldProps) {
  const {min, max, name, defaultValue, form} = props;
  const {setValue, watch, formState} = form;
  const {isSubmitting} = formState;
  const value = watch(name);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(name, newValue as number);
  };

  return (
    <Box sx={{width: 360}}>
      <Slider
        value={value || defaultValue}
        onChange={handleSliderChange}
        valueLabelFormat={valuetext}
        valueLabelDisplay="auto"
        disabled={isSubmitting}
        min={min}
        max={max}
      />
      <Box sx={{display: 'flex', mt: -1.5, justifyContent: 'space-between'}}>
        <Typography sx={{color: '#757679'}} variant="body2">
          {min} (sec)
        </Typography>
        <Typography sx={{color: '#757679'}} variant="body2">
          {max} (sec)
        </Typography>
      </Box>
    </Box>
  );
}
