import {VoiceBotsClient} from '@wildix/wim-voicebots-client';

import {ENV} from '../../env';
import token from '../../token';

let client: VoiceBotsClient | undefined;

export default function getWimVoiceBotsClient(): VoiceBotsClient {
  if (!client) {
    client = new VoiceBotsClient({
      env: ENV,
      token: {
        token: () => {
          if (token) {
            return Promise.resolve(token);
          }

          throw new Error('Not authorized.');
        },
      },
    });
  }

  return client;
}
