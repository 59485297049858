import React from 'react';

import IntegrationCardLayout from '../../../base/components/IntegrationCardLayout';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {IntegrationStatusType} from '../../../base/types';
import {VOICEBOTS_SHORT_DESCRIPTION, VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import useListVoiceBotsQuery from '../hooks/useListVoiceBotsQuery';

export default function VoiceBotsCard() {
  const query = useListVoiceBotsQuery();

  return (
    <IntegrationCardLayout
      icon={<VoiceBotsIcon width={50} height={50} />}
      title={VOICEBOTS_TITLE}
      description={VOICEBOTS_SHORT_DESCRIPTION}
      href={VoiceBotsPath.base}
      status={query.isSuccess && query.data.bots.length > 0 ? IntegrationStatusType.ENABLED : null}
      apps={['x-bees']}
    />
  );
}
