export enum VoiceBotsPath {
  base = '/voicebots',
  add = '/voicebots/add',
  edit = '/voicebots/:botId',
}

export enum VoiceBotCallbackType {
  LLM = 'LLM',
  SQS = 'SQS',
  WEBHOOK = 'WEBHOOK',
  DIALOGFLOW_CX = 'DIALOGFLOW_CX',
  OPENAI_ASSISTANT = 'OPENAI_ASSISTANT',
}
