import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

import {BotFormData} from '../../types';

interface BotFieldNameProps {
  form: UseFormReturn<BotFormData>;
}

export default function EndCallEnabledField(props: BotFieldNameProps) {
  const {form} = props;
  const {setValue, watch, formState} = form;
  const {isSubmitting} = formState;

  const isEnabled = watch('pipeline.endCallEnabled');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;
    setValue('pipeline.endCallEnabled', value === 'enabled');
  };

  return (
    <FormControl>
      <RadioGroup onChange={onChange} value={isEnabled ? 'enabled' : 'disabled'} sx={{flexDirection: 'row', mt: 1}}>
        <FormControlLabel value="enabled" control={<Radio disabled={isSubmitting} />} label="Enabled" />
        <FormControlLabel value="disabled" control={<Radio disabled={isSubmitting} />} label="Disabled" />
      </RadioGroup>
    </FormControl>
  );
}
