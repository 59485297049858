import React, {useId} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {get} from 'lodash';

import {BotFormData} from '../../../types';

interface TransferPipelineField {
  form: UseFormReturn<BotFormData>;
  path: string;
}

enum PipelineTypes {
  REPLY_AND_TRANSFER = 'reply_and_transfer',
  REPLY_INSTRUCTED_AND_TRANSFER = 'reply_instructed_and_transfer',
  TRANSFER = 'transfer',
}

export default function TransferPipelineField(props: TransferPipelineField) {
  const {form, path} = props;
  const id = useId();
  const {control, watch, formState, trigger, setValue} = form;
  const {errors, isSubmitting} = formState;

  const pipelineTypePath = `${path}.type` as any;
  const pipelineInstructionsPath = `${path}.instructions` as any;
  const pipelineType = watch(pipelineTypePath) || PipelineTypes.REPLY_AND_TRANSFER;
  const pipelineInstructionsError = get(errors, pipelineInstructionsPath);

  const onTypeChange = (event: SelectChangeEvent) => {
    setValue(pipelineTypePath, event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel size="small" id={`${id}-select-label`}>
          Pipeline
        </InputLabel>
        <Select
          labelId={`${id}-select-label`}
          value={pipelineType}
          disabled={isSubmitting}
          size="small"
          label="Pipeline"
          onChange={onTypeChange}>
          <MenuItem value={PipelineTypes.REPLY_AND_TRANSFER}>
            Generate a reply and transfer the call after playback
          </MenuItem>
          <MenuItem value={PipelineTypes.REPLY_INSTRUCTED_AND_TRANSFER}>
            Generate a reply as instructed and transfer the call after playback
          </MenuItem>
          <MenuItem value={PipelineTypes.TRANSFER}>Transfer the call without replying</MenuItem>
        </Select>
      </FormControl>
      {pipelineType === PipelineTypes.REPLY_INSTRUCTED_AND_TRANSFER && (
        <Box mt={2}>
          <Controller
            control={control}
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextField
                multiline
                minRows={2}
                maxRows={4}
                autoComplete="off"
                inputRef={ref}
                name={name}
                size="small"
                fullWidth
                label="Reply instrustions"
                placeholder="Transfer the call and reply 'Transfering you to human'"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={() => {
                  trigger(name);
                  onBlur();
                }}
                onChange={onChange}
                disabled={isSubmitting}
                value={value || ''}
                error={!!pipelineInstructionsError}
                helperText={
                  pipelineInstructionsError
                    ? pipelineInstructionsError.message || pipelineInstructionsError.type
                    : undefined
                }
              />
            )}
            name={pipelineInstructionsPath}
            rules={{required: true}}
          />
        </Box>
      )}
    </Box>
  );
}
