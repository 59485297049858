import React, {useState} from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';

import {VoiceBotEndpoint} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';

interface BotSqsSecretFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotSqsSecretField(props: BotSqsSecretFieldProps) {
  const {form} = props;
  const {control, formState, getValues, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.endpoint as FieldErrors<VoiceBotEndpoint.SqsMember>)?.sqs?.secret;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value, name, ref}}) => (
          <TextField
            inputRef={ref}
            name={name}
            type={showPassword ? 'text' : 'password'}
            size="small"
            required
            fullWidth
            label="Secret"
            placeholder="AWS Secret"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onBlur={onBlur}
            onChange={onChange}
            disabled={isSubmitting}
            value={value}
            error={!!error}
            helperText={error?.message || error?.type}
          />
        )}
        name="endpoint.sqs.secret"
        rules={{required: true}}
      />
    </div>
  );
}
