import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, TextField, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {VoiceBotTool} from '@wildix/wim-voicebots-client';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import FunctionDescriptionField from './FunctionDescriptionField';
import FunctionIntegrationWebhookPipelineField from './FunctionIntegrationWebhookPipelineField';
import FunctionIntegrationWebhookUrlField from './FunctionIntegrationWebhookUrlField';
import FunctionNameField from './FunctionNameField';
import FunctionParametersField from './FunctionParametersField';

interface WebhookToolProps {
  position: number;
  index: number;
  tool: VoiceBotTool;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionTool(props: WebhookToolProps) {
  const {position, index, form, path} = props;
  const {watch, setValue} = form;
  const tools = watch('endpoint.llm.tools');

  const onDeleteClick = () => {
    setValue(
      'endpoint.llm.tools',
      (tools || []).filter((item, itemIndex) => itemIndex !== index),
    );
  };

  return (
    <Box sx={{pt: 1, pb: 3}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Third-party Function</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />
        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
        Allows to integrate your data sources or APIs. This lets the voicebot make HTTP API calls to get data or start
        specific API requests when certain events happen during the conversation.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <Box>
          <FunctionNameField form={form} path={`${path}.function.name`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * Tool name is a short name of your tool. Example: <b>get_available_slots</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <FunctionDescriptionField form={form} path={`${path}.function.description`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * The description is essential for directing the voicebot on when to utilize your tool. Example:{' '}
            <b>Gets available timeslots for the appointment.</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <FunctionParametersField form={form} path={`${path}.function.parameters`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * The parameters the functions accepts, described as a JSON Schema object. See the{' '}
            <a href="https://platform.openai.com/docs/guides/function-calling" target="_blank" rel="noreferrer">
              guide
            </a>{' '}
            for examples, and the{' '}
            <a href="https://json-schema.org/understanding-json-schema" target="_blank" rel="noreferrer">
              JSON Schema reference
            </a>{' '}
            for documentation about the format.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography sx={{fontSize: '16px'}}>Integration</Typography>
          {/*<Box mt={1}>*/}
          {/*  <RadioGroup row name="row-radio-buttons-group">*/}
          {/*    <FormControlLabel value="webhook" control={<Radio checked />} label="Generic web service" />*/}
          {/*    <FormControlLabel value="make" disabled control={<Radio />} label="Make.com" />*/}
          {/*    <FormControlLabel value="zapier" disabled control={<Radio />} label="Zapier" />*/}
          {/*  </RadioGroup>*/}
          {/*</Box>*/}
          <Box mt={2}>
            <FunctionIntegrationWebhookUrlField form={form} path={`${path}.function.integration.webhook.url`} />
            {/*<Alert severity="info" sx={{mt: 1}}>*/}
            {/*  The endpoint should be publicly accessible with a valid HTTPS certificate, accept HTTP POST requests with*/}
            {/*  JSON payloads, respond with codes from 200 to 299 as quickly as possible.*/}
            {/*</Alert>*/}
            <Box mt={2}>
              <FunctionIntegrationWebhookPipelineField form={form} path={`${path}.function.integration.webhook`} />
            </Box>
          </Box>
          {false && (
            <>
              <Box mt={2}>
                <Typography sx={{fontSize: '14px', fontWeight: '500'}}>Authorization</Typography>
                <RadioGroup row name="row-radio-buttons-group2">
                  <FormControlLabel value="none" control={<Radio checked />} label="None" />
                  <FormControlLabel value="token" disabled control={<Radio />} label="Token" />
                  <FormControlLabel value="basic" disabled control={<Radio />} label="Basic" />
                  <FormControlLabel value="oauth" disabled control={<Radio />} label="Third-party OAuth" />
                </RadioGroup>
              </Box>
              <Box mt={2}>
                <Typography sx={{fontSize: '14px', fontWeight: '500'}}>Headers</Typography>
                <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                  Specify required headers that the service accepts.
                </Typography>
                <Stack flexDirection="row" mt={1} alignItems="center">
                  <TextField fullWidth required value="" size="small" label="Key" />
                  <TextField sx={{ml: 1}} fullWidth required value="" size="small" label="Value" />
                </Stack>
                <Box mt={1}>
                  <Button variant="outlined" size="small" onClick={() => alert('TODO')}>
                    Add header
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
