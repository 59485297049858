export default function VoiceBotsIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M30.9855 9C30.194 9 29.5015 9.69227 29.5015 10.4834V36.7895C29.5015 37.5807 30.194 38.2729 30.9855 38.2729C31.7769 38.2729 32.4694 37.5807 32.4694 36.7895V10.4834C32.4694 9.69227 31.7769 9 30.9855 9Z"
        fill="#595959"
      />
      <path
        d="M10.21 30.6602C11.0014 30.6602 11.6939 29.9679 11.6939 29.1767V18.1005C11.6939 17.3093 11.0014 16.6171 10.21 16.6171C9.41852 16.6171 8.72601 17.3093 8.72601 18.1005V29.1767C8.72601 29.9679 9.31959 30.6602 10.21 30.6602Z"
        fill="#595959"
      />
      <path
        d="M5.1815 27.8974C5.81164 27.8974 6.363 27.548 6.363 27.1488V21.5594C6.363 21.1602 5.81164 20.8109 5.1815 20.8109C4.55137 20.8109 4 21.1602 4 21.5594V27.1488C4 27.548 4.4726 27.8974 5.1815 27.8974Z"
        fill="#595959"
      />
      <path
        d="M42.9895 27.8974C43.6197 27.8974 44.171 27.548 44.171 27.1488V21.5594C44.171 21.1602 43.6197 20.8109 42.9895 20.8109C42.3594 20.8109 41.808 21.1602 41.808 21.5594V27.1488C41.808 27.548 42.2806 27.8974 42.9895 27.8974Z"
        fill="#595959"
      />
      <path
        d="M37.9602 31.0754C38.7517 31.0754 39.4442 30.3831 39.4442 29.592V18.5157C39.4442 17.7246 38.7517 17.0323 37.9602 17.0323C37.1688 17.0323 36.4763 17.7246 36.4763 18.5157V29.592C36.4763 30.3831 37.0699 31.0754 37.9602 31.0754Z"
        fill="#595959"
      />
      <path
        d="M24.0609 13.4521C23.2694 13.4521 22.5769 14.1444 22.5769 14.9356V32.3411C22.5769 33.1323 23.2694 33.8245 24.0609 33.8245C24.8523 33.8245 25.5448 33.1323 25.5448 32.3411V14.9356C25.5448 14.1444 24.8523 13.4521 24.0609 13.4521Z"
        fill="#595959"
      />
      <path
        d="M17.1351 9C16.3437 9 15.6512 9.69227 15.6512 10.4834V36.7895C15.6512 37.5807 16.3437 38.2729 17.1351 38.2729C17.9266 38.2729 18.6191 37.5807 18.6191 36.7895V10.4834C18.6191 9.69227 17.9266 9 17.1351 9Z"
        fill="#595959"
      />
    </svg>
  );
}
