import React from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {VoiceBotEndpoint} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';

// import {TransmissionType} from '../../types';

interface BotLlmPromptFieldProps {
  form: UseFormReturn<BotFormData>;
}

const placeholder = `
You are the virtual concierge for Sunrise Yoga Studio, located at 321 Sunset Drive, Miami, Florida. The studio is open from 6 AM to 8 PM every day, with a variety of classes offered throughout the day.

Your task is to answer questions about classes, schedule attendees, and manage memberships.

For class registration:

1.Ask for their name.
2.Ask which type of yoga class they’re interested in (beginner, intermediate, advanced, or specialty class).
3.Request their preferred class time and date.
4.Confirm the class and time, reminding them of any necessary details (like bringing a mat or water bottle).

•Be calm and relaxed, like you’re speaking from a yoga studio!
•Keep the conversation flowing with a chill, zen vibe. Make it short but warm, like you’re helping them find their inner peace.
`.trim();

export default function BotLlmPromptField(props: BotLlmPromptFieldProps) {
  const {form} = props;
  const {control, formState, trigger, getValues} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.endpoint as FieldErrors<VoiceBotEndpoint.LlmMember>)?.llm?.prompt;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          multiline
          minRows={8}
          maxRows={20}
          autoComplete="off"
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Instructions"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value || ''}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="endpoint.llm.prompt"
      rules={{required: true}}
    />
  );
}
