import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {BotFormData} from '../../types';

interface BotFieldNameProps {
  form: UseFormReturn<BotFormData>;
}

export default function EndCallPhrasesField(props: BotFieldNameProps) {
  const {form} = props;
  const {control, watch, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.name;
  const isEnabled = watch('pipeline.endCallEnabled');

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="End Call Phrases"
          placeholder="goodbye,talk to you soon"
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting || !isEnabled}
          value={value || ''}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="pipeline.endCallPhases"
    />
  );
}
