import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';

import {UpdateVoiceBotCommand} from '@wildix/wim-voicebots-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import denormalizeVoiceBotFormEndpoint from '../helpers/denormalizeVoiceBotFormEndpoint';
import normalizeVoiceBotFormEndpoint from '../helpers/normalizeVoiceBotFormEndpoint';
import useGetVoiceBotQuery from '../hooks/useGetVoiceBotQuery';
import {BotFormData} from '../types';
import VoiceBotForm from './VoiceBotForm';

interface BotsAddScreenParams extends Record<string, string> {
  botId: string;
}

export default function VoiceBotEditScreen() {
  const params = useParams<BotsAddScreenParams>();
  const navigate = useNavigate();
  const form = useForm<BotFormData>({});
  const {setError} = form;
  const [initialized, setInitialized] = useState(false);

  const queryClient = useQueryClient();
  const queryBotInfo = useGetVoiceBotQuery(params.botId);

  useEffect(() => {
    if (queryBotInfo.data && !initialized) {
      form.reset({
        id: params.botId,
        name: queryBotInfo.data.bot.name,
        message: queryBotInfo.data.bot.message,
        endpoint: denormalizeVoiceBotFormEndpoint(queryBotInfo.data.bot.endpoint),
        pipeline: queryBotInfo.data.bot.pipeline,
      });
      setInitialized(true);
    }
  }, [queryBotInfo.data]);

  const onSubmit = useCallback(
    async (data: BotFormData) => {
      const {name, message, endpoint, pipeline} = data;
      const client = getWimVoiceBotsClient();

      try {
        await client.send(
          new UpdateVoiceBotCommand({
            botId: params.botId!,
            name,
            message,
            endpoint: normalizeVoiceBotFormEndpoint(endpoint),
            pipeline,
          }),
        );

        queryClient.resetQueries('voicebots');

        navigate(VoiceBotsPath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  if (queryBotInfo.isError) {
    return <ApiErrorBox error={queryBotInfo.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={VoiceBotsPath.base}>
          {VOICEBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">
          {queryBotInfo.data ? queryBotInfo.data.bot.name : <CircularProgress size={12} />}
        </Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <VoiceBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            {queryBotInfo.data ? queryBotInfo.data.bot.name : <CircularProgress size={12} />}
          </Typography>

          {queryBotInfo.isLoading ? (
            <Box mt={5}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
          {initialized ? <VoiceBotForm form={form} onSubmit={onSubmit} /> : null}
        </Box>
      </Box>
    </Box>
  );
}
