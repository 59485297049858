import React, {useId} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {Stack, TextField, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {get} from 'lodash';

import {BotFormData} from '../../../types';

interface FunctionIntegrationWehookAsyncFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

enum PipelineTypes {
  BLOCKING_REQUEST = 'blocking_request',
  BLOCKING_REQUEST_GUIDED = 'blocking_request_guided',
  ASYNC_REQUEST = 'async_request',
  ASYNC_REQUEST_GUIDED = 'async_request_guided',
}

export default function FunctionIntegrationWebhookPipelineField(props: FunctionIntegrationWehookAsyncFieldProps) {
  const {form, path} = props;
  const {control, watch, formState, trigger, setValue} = form;
  const {errors, isSubmitting} = formState;
  const id = useId();
  const replyTypePath = `${path}.parameters.type` as any;
  const replyInstructionsPath = `${path}.parameters.instructions` as any;
  const asyncPath = `${path}.async` as any;
  const async = watch(asyncPath);
  const replyType = watch(replyTypePath);
  const replyInstructionsError = get(errors, path);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(
      asyncPath,
      event.target.value === PipelineTypes.ASYNC_REQUEST || event.target.value === PipelineTypes.ASYNC_REQUEST_GUIDED,
    );

    if (event.target.value === PipelineTypes.BLOCKING_REQUEST || event.target.value === PipelineTypes.ASYNC_REQUEST) {
      setValue(replyTypePath, null);
    } else {
      setValue(replyTypePath, 'guided');
    }
  };

  let pipelineType = PipelineTypes.BLOCKING_REQUEST;

  if (async && replyType === 'guided') {
    pipelineType = PipelineTypes.ASYNC_REQUEST_GUIDED;
  } else if (async) {
    pipelineType = PipelineTypes.ASYNC_REQUEST;
  } else if (replyType === 'guided') {
    pipelineType = PipelineTypes.BLOCKING_REQUEST_GUIDED;
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel size="small" id={`${id}-select-label`}>
          Pipeline
        </InputLabel>
        <Select
          labelId={`${id}-select-label`}
          value={pipelineType}
          disabled={isSubmitting}
          size="small"
          label="Pipeline"
          placeholder="{}"
          onChange={handleChange}>
          <MenuItem value={PipelineTypes.BLOCKING_REQUEST}>
            <Stack flexDirection="column">
              <div>Blocking request</div>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Wait for response and use response in the reply.
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem value={PipelineTypes.BLOCKING_REQUEST_GUIDED}>
            <Stack flexDirection="column">
              <div>Blocking request guided</div>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Wait for response and use instructions to generate a reply.
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem value={PipelineTypes.ASYNC_REQUEST}>
            <Stack flexDirection="column">
              <div>Async request</div>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>Do not wait for response.</Typography>
            </Stack>
          </MenuItem>
          <MenuItem value={PipelineTypes.ASYNC_REQUEST_GUIDED}>
            <Stack flexDirection="column">
              <div>Async request guided</div>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Do not wait for response and use instructions to generate a reply.
              </Typography>
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
      {replyType === 'guided' && (
        <Box mt={2}>
          <Controller
            control={control}
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextField
                multiline
                minRows={2}
                maxRows={4}
                autoComplete="off"
                inputRef={ref}
                name={name}
                size="small"
                fullWidth
                label="Reply instrustions"
                placeholder={
                  async
                    ? 'Reply that your appointment is confirmed and you will receive SMS with all details.'
                    : 'Available slots: {response}\nInstruction: Reply just an available time and ask if this time works.'
                }
                required
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={() => {
                  trigger(name);
                  onBlur();
                }}
                onChange={onChange}
                disabled={isSubmitting}
                value={value || ''}
                error={!!replyInstructionsError}
                helperText={
                  replyInstructionsError ? replyInstructionsError.message || replyInstructionsError.type : undefined
                }
              />
            )}
            name={replyInstructionsPath}
            rules={{required: true}}
          />
          {!async && (
            <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
              * Use <b>{'{response}'}</b> in instructions, which will be replaced with the response from the service.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
