import {VoiceBotEndpoint, VoiceBotTool} from '@wildix/wim-voicebots-client';

function normalizeTool(tool: VoiceBotTool): VoiceBotTool {
  if (!tool.function?.parameters) {
    return tool;
  }

  return {
    ...tool,
    function: {
      ...tool.function,
      parameters: JSON.parse(tool.function.parameters as string),
    },
  };
}

export default function normalizeVoiceBotFormEndpoint(endpoint: VoiceBotEndpoint): VoiceBotEndpoint {
  if (endpoint.llm && endpoint.llm.tools && endpoint.llm.tools.length > 0) {
    return {
      llm: {
        ...endpoint.llm,
        tools: endpoint.llm.tools.map(normalizeTool),
      },
    };
  }

  return endpoint;
}
