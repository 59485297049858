import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, Typography} from '@mui/material';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import HangupDescriptionField from './HangupDescriptionField';
import HangupPipelineField from './HangupPipelineField';

interface HangupToolProps {
  position: number;
  index: number;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function HangupTool(props: HangupToolProps) {
  const {position, form, path, index} = props;
  const {watch, setValue} = form;
  const embeddedTools = watch('endpoint.llm.embeddedTools');

  const onDeleteClick = () => {
    setValue(
      'endpoint.llm.embeddedTools',
      (embeddedTools || []).filter((item, itemIndex) => itemIndex !== index),
    );
  };

  return (
    <Box sx={{pt: 1}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Hangup</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />

        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 2}}>
        Allow the voicebot to end the call after the conversation is complete or upon the user's request, thereby
        improving the user experience. By setting a custom description, you can guide the voicebot to make better
        decisions about when to end the call.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <HangupDescriptionField form={form} path={`${path}.parameters.description`} />
        <Box mt={2}>
          <HangupPipelineField form={form} path={`${path}.parameters.pipeline`} />
        </Box>
      </Box>
    </Box>
  );
}
