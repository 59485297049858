import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const StyledAccordionSummary = styled(AccordionSummary)({
  fontSize: '16px',
  padding: '18px 24px',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
});

const StyledAccordion = styled(Accordion)({
  // marginTop: '24px',
  // borderTopWidth: '0',

  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',

  '&:first-of-type': {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '&:nth-of-type(1)': {
    borderTopWidth: '1px',
  },
  '&.Mui-expanded': {
    borderTopWidth: '1px',
    borderRadius: '16px',
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0 24px 24px',
});

interface EventsAccordionProps {
  title: string;
  children: React.ReactNode;
}

export default function AdvancedSettingsAccordion(props: EventsAccordionProps) {
  const {title, children} = props;

  return (
    <StyledAccordion sx={{mt: 2}} elevation={0} variant="outlined">
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
