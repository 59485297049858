import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, Typography} from '@mui/material';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import TransferContextField from './TransferContextField';
import TransferDescriptionField from './TransferDescriptionField';
import TransferExtensionField from './TransferExtensionField';
import TransferPipelineField from './TransferPipelineField';

interface TransferToolProps {
  position: number;
  index: number;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function TransferTool(props: TransferToolProps) {
  const {position, index, form, path} = props;
  const {watch, setValue} = form;
  const embeddedTools = watch('endpoint.llm.embeddedTools');

  const onDeleteClick = () => {
    setValue(
      'endpoint.llm.embeddedTools',
      (embeddedTools || []).filter((item, itemIndex) => itemIndex !== index),
    );
  };

  return (
    <Box sx={{pt: 1}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Transfer</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />

        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
        Allows to transfer calls to certain extensions in the dialplan, allowing complex inquiries to be handled by a
        human representative for better customer satisfaction. By setting a custom description, you can guide the
        voicebot to make better decisions about when to transfer the call.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <Box>
          <TransferDescriptionField form={form} path={`${path}.parameters.description`} />
          <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
            * Specified URL will be used by the integration to send POST requests with the event payload.
          </Typography>
        </Box>
        <Box mt={2}>
          <TransferContextField form={form} path={`${path}.parameters.context`} />
        </Box>
        <Box mt={2}>
          <TransferExtensionField form={form} path={`${path}.parameters.extension`} />
        </Box>
        <Box mt={2}>
          <TransferPipelineField form={form} path={`${path}.parameters.pipeline`} />
        </Box>
      </Box>
    </Box>
  );
}
