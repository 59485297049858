import {useQuery} from 'react-query';

import {ListVoiceBotsCommand} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';

export default function useListVoiceBotsQuery() {
  return useQuery('voicebots', () => getWimVoiceBotsClient().send(new ListVoiceBotsCommand({})), {
    retry: false,
  });
}
