import React, {useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, IconButton, Stack} from '@mui/material';

import {VoiceBot} from '@wildix/wim-voicebots-client';

import IntegrationDeleteDialog from '../../../../base/components/IntegrationDeleteDialog';
import formatDateString from '../../../../base/helpers/formatDateString';
import PencilIcon20 from '../../../../base/icons/PencilIcon20';
import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {VoiceBotsPath} from '../../constants';
import useDeleteBotMutation from '../../hooks/useDeleteVoiceBotMutation';
import ListItemTable from './ListItemTable';

interface BotListItemProps {
  bot: VoiceBot;
}

export default function BotListItem(props: BotListItemProps) {
  const {bot} = props;
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(`${VoiceBotsPath.base}/${bot.id}`);
  };

  const callbackType = bot.endpoint.llm
    ? 'LLM'
    : bot.endpoint.sqs
      ? 'AWS SQS'
      : bot.endpoint.webhook
        ? 'Webhook'
        : bot.endpoint.dialogflowCx
          ? 'Dialogflow CX'
          : bot.endpoint.openAiAssistant
            ? 'OpenAI Assistant'
            : '-';

  const {mutate: deleteMutate, isLoading: isDeleteLoading} = useDeleteBotMutation({
    onSuccess: () => {
      // TODO: nothing ? toast ?
      // navigate(VoiceBotsPath.base);
    },
  });

  const removeDialogRef = useRef<any>();
  const onDeleteClick = useCallback(() => {
    removeDialogRef.current.open();
  }, []);
  const onDeleteConfirmClick = useCallback(() => {
    if (bot) {
      deleteMutate(bot.id);
    }
  }, [bot, deleteMutate]);

  return (
    <Box mt={3}>
      <Stack display="flex" flexDirection="row" alignItems="flex-end">
        <Box flex="1" sx={{height: '44px'}}>
          <Box>{bot.name}</Box>
          <Box sx={{fontSize: '13px', color: '#757679'}}>Created at: {formatDateString(bot.createdAt)}</Box>
        </Box>
        <Stack display="flex" flexDirection="row">
          <IconButton onClick={onEditClick} sx={{width: '32px', height: '32px', padding: '6px'}}>
            <PencilIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
          <IconButton onClick={onDeleteClick} sx={{width: '32px', height: '32px', padding: '6px'}}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Stack>
      </Stack>
      <Box mt={1}>
        <ListItemTable
          rows={[
            {label: 'Bot ID', value: bot.id},
            {label: 'Integration', value: callbackType},
          ]}
        />
      </Box>
      <IntegrationDeleteDialog apiRef={removeDialogRef} title={`Bot #${bot.id}`} onDeleteClick={onDeleteConfirmClick} />
    </Box>
  );
}
