import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import Ajv from 'ajv';
import {get} from 'lodash';

import {BotFormData} from '../../../types';

interface FunctionParametersFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

const ajv = new Ajv();

function validateParametersValue(value: string) {
  if (!value || value.trim() === '') {
    // If the field is empty, consider it valid (or adjust according to your requirements)
    return true;
  }

  // TODO
  // Invalid schema for function 'test': schema must be a JSON Schema of 'type: "object"', got 'type: "string"'.

  try {
    const json = JSON.parse(value);

    // Validate the JSON Schema using Ajv's validateSchema method
    const valid = ajv.validateSchema(json);

    if (!valid) {
      // If the schema is invalid, ajv.errors contains the validation errors
      const errorMessage = ajv.errorsText(ajv.errors, {separator: '\n'});

      // Return the error message to display it in the TextField's helperText
      return errorMessage || 'Invalid JSON Schema.';
    }
  } catch (e) {
    // If JSON parsing fails, return an error message
    return 'Invalid JSON format.';
  }

  // If everything is valid, return true
  return true;
}

export default function FunctionParametersField(props: FunctionParametersFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          multiline
          minRows={2}
          maxRows={4}
          autoComplete="off"
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          label="Parameters"
          placeholder="{}"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value || ''}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name={path as any}
      rules={{
        required: true,
        validate: {
          schema: validateParametersValue,
        },
      }}
    />
  );
}
