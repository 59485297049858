import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function LlmIcon24(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M27,24a2.9609,2.9609,0,0,0-1.2854.3008L21.4141,20H18v2h2.5859l3.7146,3.7148A2.9665,2.9665,0,0,0,24,27a3,3,0,1,0,3-3Zm0,4a1,1,0,1,1,1-1A1.0009,1.0009,0,0,1,27,28Z" />
      <path d="M27,13a2.9948,2.9948,0,0,0-2.8157,2H18v2h6.1843A2.9947,2.9947,0,1,0,27,13Zm0,4a1,1,0,1,1,1-1A1.0009,1.0009,0,0,1,27,17Z" />
      <path d="M27,2a3.0033,3.0033,0,0,0-3,3,2.9657,2.9657,0,0,0,.3481,1.373L20.5957,10H18v2h3.4043l4.3989-4.2524A2.9987,2.9987,0,1,0,27,2Zm0,4a1,1,0,1,1,1-1A1.0009,1.0009,0,0,1,27,6Z" />
      <path d="M18,6h2V4H18a3.9756,3.9756,0,0,0-3,1.3823A3.9756,3.9756,0,0,0,12,4H11a9.01,9.01,0,0,0-9,9v6a9.01,9.01,0,0,0,9,9h1a3.9756,3.9756,0,0,0,3-1.3823A3.9756,3.9756,0,0,0,18,28h2V26H18a2.0023,2.0023,0,0,1-2-2V8A2.0023,2.0023,0,0,1,18,6ZM12,26H11a7.0047,7.0047,0,0,1-6.92-6H6V18H4V14H7a3.0033,3.0033,0,0,0,3-3V9H8v2a1.0009,1.0009,0,0,1-1,1H4.08A7.0047,7.0047,0,0,1,11,6h1a2.0023,2.0023,0,0,1,2,2v4H12v2h2v4H12a3.0033,3.0033,0,0,0-3,3v2h2V21a1.0009,1.0009,0,0,1,1-1h2v4A2.0023,2.0023,0,0,1,12,26Z" />

      {/*<path*/}
      {/*  d="M11.0674 10.5471C10.2634 11.8973 9.48967 13.2096 8.70078 14.5143C8.49598 14.8481 8.39736 15.1211 8.55666 15.5459C9.0042 16.7217 8.37461 17.8595 7.19128 18.1705C6.07622 18.4663 4.99151 17.7305 4.77153 16.5396C4.57431 15.4852 5.39353 14.446 6.56169 14.2792C6.6603 14.264 6.75891 14.264 6.92579 14.2488C7.4947 13.2931 8.07878 12.3145 8.70078 11.2753C7.58572 10.1679 6.92579 8.87075 7.06991 7.27022C7.17611 6.13241 7.61607 5.15389 8.43529 4.34983C9.99789 2.80999 12.3797 2.55967 14.2154 3.743C15.9828 4.88081 16.7869 7.08817 16.1042 8.97695C15.5808 8.83282 15.0574 8.69628 14.4885 8.53699C14.7084 7.48261 14.5416 6.54202 13.8361 5.73038C13.3658 5.1994 12.7666 4.91874 12.0839 4.81254C10.7109 4.60774 9.3683 5.48765 8.96627 6.83027C8.51115 8.36253 9.19384 9.60654 11.0674 10.5471Z"*/}
      {/*  fill="#C73A63"*/}
      {/*/>*/}
      {/*<path*/}
      {/*  d="M13.367 8.94663C13.9359 9.94791 14.5124 10.9644 15.0813 11.9656C17.9638 11.0706 20.1408 12.6711 20.9221 14.3778C21.8627 16.441 21.2179 18.8911 19.3671 20.1579C17.4707 21.4626 15.0662 21.2426 13.3822 19.5587C13.8146 19.2021 14.2393 18.838 14.7021 18.4512C16.3633 19.5283 17.8197 19.4752 18.8968 18.2009C19.8146 17.1161 19.7995 15.4929 18.8513 14.4233C17.759 13.1945 16.295 13.1565 14.5276 14.3399C13.7918 13.0352 13.0484 11.7457 12.3354 10.4334C12.0927 9.99342 11.8348 9.73551 11.2886 9.64449C10.3859 9.4852 9.80186 8.71148 9.77152 7.84674C9.73359 6.98959 10.2418 6.21587 11.0307 5.91246C11.812 5.61662 12.7298 5.85177 13.2608 6.51929C13.6932 7.06544 13.8297 7.67228 13.6022 8.34738C13.5415 8.5446 13.458 8.72665 13.367 8.94663Z"*/}
      {/*  fill="#4B4B4B"*/}
      {/*/>*/}
      {/*<path*/}
      {/*  d="M14.7251 17.1236H11.251C10.9172 18.489 10.1966 19.5965 8.96016 20.3019C7.99681 20.8481 6.9576 21.0301 5.85771 20.8557C3.82482 20.5295 2.16361 18.6938 2.0119 16.6382C1.84502 14.3019 3.45313 12.231 5.59222 11.7607C5.73635 12.2993 5.88806 12.8379 6.03976 13.3764C4.07514 14.3777 3.40003 15.6369 3.94619 17.2147C4.43165 18.6028 5.80462 19.3613 7.29136 19.0731C8.81603 18.7697 9.58216 17.5105 9.49114 15.4776C10.9324 15.4776 12.3812 15.4624 13.8224 15.4852C14.3837 15.4928 14.8237 15.4321 15.2485 14.939C15.9463 14.1198 17.2359 14.1957 17.9868 14.9694C18.753 15.7583 18.7226 17.0326 17.9034 17.7836C17.1145 18.5118 15.8781 18.4739 15.1423 17.685C14.9906 17.5181 14.8768 17.3209 14.7251 17.1236Z"*/}
      {/*  fill="#4A4A4A"*/}
      {/*/>*/}
    </SvgIcon>
  );
}
