import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {BotFormData} from '../../types';

interface BotFieldNameProps {
  form: UseFormReturn<BotFormData>;
}

export default function MessageField(props: BotFieldNameProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {isSubmitting} = formState;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          label="First message"
          placeholder="Hello! How can I assist you today?"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value || ''}
          helperText="The message that the bot will say at the beginning of the session (optional)."
        />
      )}
      name="message"
    />
  );
}
