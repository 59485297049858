import {useQuery} from 'react-query';

import {GetVoiceBotCommand} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';

export default function useGetVoiceBotQuery(botId?: string) {
  return useQuery(
    ['voicebots', botId],
    () => getWimVoiceBotsClient().send(new GetVoiceBotCommand({botId: botId || ''})),
    {
      retry: false,
      enabled: !!botId,
    },
  );
}
