import React from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {VoiceBotEndpoint} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';

interface BotDialogflowCxAgentIdFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotDialogflowCxAgentField(props: BotDialogflowCxAgentIdFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.endpoint as FieldErrors<VoiceBotEndpoint.DialogflowCxMember>)?.dialogflowCx?.agent;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Agent ID"
          placeholder="Dialogflow CX Agent ID"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="endpoint.dialogflowCx.agent"
      rules={{required: true, minLength: 3, maxLength: 255}}
    />
  );
}
