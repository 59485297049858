import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import CancelButton from '../../../base/components/button/CancelButton';
import getRandomString from '../../../base/helpers/getRandomString';
import DialogflowIcon24 from '../../../base/icons/DialogflowIcon24';
import LlmIcon24 from '../../../base/icons/LlmIcon24';
import OpenaiIcon24 from '../../../base/icons/OpenaiIcon24';
import SqsIcon24 from '../../../base/icons/SqsIcon24';
import WebhookIcon24 from '../../../base/icons/WebhookIcon24';
import {VoiceBotCallbackType, VoiceBotsPath} from '../constants';
import {BotFormData} from '../types';
import AdvancedSettingsAccordion from './advanced/AdvancedSettingsAccordion';
import BotDialogflowCxAgentField from './fields/BotDialogflowCxAgentField';
import BotDialogflowCxCredentialsField from './fields/BotDialogflowCxCredentialsField';
import BotDialogflowCxLanguageField from './fields/BotDialogflowCxLanguageField';
import BotDialogflowCxLocationField from './fields/BotDialogflowCxLocationField';
import BotLlmFunctionsField from './fields/BotLlmFunctionsField';
import BotLlmPromptField from './fields/BotLlmPromptField';
import BotNameField from './fields/BotNameField';
import BotOpenAiAssistantField from './fields/BotOpenAiAssistantField';
import BotOpenAiKeyField from './fields/BotOpenAiKeyField';
import BotSqsKeyField from './fields/BotSqsKeyField';
import BotSqsSecretField from './fields/BotSqsSecretField';
import BotSqsUrlField from './fields/BotSqsUrlField';
import BotWebhookSecretField from './fields/BotWebhookSecretField';
import BotWebhookUrlField from './fields/BotWebhookUrlField';
import EndCallEnabledField from './fields/EndCallEnabledField';
import EndCallPhrasesField from './fields/EndCallPhrasesField';
import InterruptionsEnabledField from './fields/InterruptionsEnabledField';
import MessageField from './fields/MessageField';
import TimeoutSliderField from './fields/TimeoutSliderField';

interface BotFormProps {
  form: UseFormReturn<BotFormData>;
  onSubmit: (data: BotFormData) => void;
}

export default function VoiceBotForm(props: BotFormProps) {
  const navigate = useNavigate();
  const {form, onSubmit} = props;
  const {handleSubmit, formState, watch, setValue} = form;
  const {isSubmitting} = formState;

  const id = watch('id');
  const endpoint = watch('endpoint');
  const type = endpoint?.llm
    ? VoiceBotCallbackType.LLM
    : endpoint?.sqs
      ? VoiceBotCallbackType.SQS
      : endpoint?.webhook
        ? VoiceBotCallbackType.WEBHOOK
        : endpoint?.dialogflowCx
          ? VoiceBotCallbackType.DIALOGFLOW_CX
          : endpoint?.openAiAssistant
            ? VoiceBotCallbackType.OPENAI_ASSISTANT
            : VoiceBotCallbackType.LLM;

  const onTypeChange = (e: React.MouseEvent<HTMLElement>, value: unknown) => {
    if (!value) {
      return;
    }

    if (value === VoiceBotCallbackType.LLM) {
      setValue('endpoint', {
        llm: {
          prompt: '',
        },
      });
    } else if (value === VoiceBotCallbackType.WEBHOOK) {
      setValue('endpoint', {
        webhook: {
          url: '',
          secret: getRandomString(),
        },
      });
    } else if (value === VoiceBotCallbackType.SQS) {
      setValue('endpoint', {
        sqs: {
          url: '',
          key: '',
          secret: '',
        },
      });
    } else if (value === VoiceBotCallbackType.DIALOGFLOW_CX) {
      setValue('endpoint', {
        dialogflowCx: {
          credentials: null,
          agent: '',
          location: '',
          language: '',
        },
      });
    } else if (value === VoiceBotCallbackType.OPENAI_ASSISTANT) {
      setValue('endpoint', {
        openAiAssistant: {
          key: '',
          assistantId: '',
        },
      });
    } else {
      throw new Error(`Illegal ${value} callback type.`);
    }
  };

  const onCancelClick = () => {
    navigate(VoiceBotsPath.base);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2}>
        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 4}}>Select name and first message.</Typography>
        <Box mt={2} width="75%">
          <BotNameField form={form} />
        </Box>
        <Box mt={3} width="75%">
          <MessageField form={form} />
        </Box>
        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 4}}>Select the integration type.</Typography>
        <Box mt={1} height="48px">
          <ToggleButtonGroup
            sx={{position: 'absolute', whiteSpace: 'nowrap'}}
            disabled={isSubmitting}
            size="medium"
            exclusive
            value={type}
            onChange={onTypeChange}>
            <ToggleButton value={VoiceBotCallbackType.LLM}>
              <LlmIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Generative AI
              </Box>
            </ToggleButton>
            <ToggleButton value={VoiceBotCallbackType.WEBHOOK}>
              <WebhookIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Webhook
              </Box>
            </ToggleButton>
            <ToggleButton value={VoiceBotCallbackType.SQS}>
              <SqsIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                AWS SQS
              </Box>
            </ToggleButton>
            <ToggleButton value={VoiceBotCallbackType.DIALOGFLOW_CX}>
              <DialogflowIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Dialogflow CX
              </Box>
            </ToggleButton>
            <ToggleButton value={VoiceBotCallbackType.OPENAI_ASSISTANT}>
              <OpenaiIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                OpenAI assistant
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box mt={2}>
          {type === VoiceBotCallbackType.LLM && (
            <>
              <Box mt={3}>
                <Typography sx={{fontSize: '16px', fontWeight: '500', pb: 0.5}}>Instructions</Typography>
                <Typography sx={{fontSize: '12px', color: '#757679', pb: 3}}>
                  Instructions can be used to configure the context, role, personality, tasks and so on for the
                  assistant.
                </Typography>
                <BotLlmPromptField form={form} />
              </Box>
              <Box mt={3}>
                <Typography sx={{fontSize: '16px', fontWeight: '500', pb: 0.5}}>Tools</Typography>
                <Typography sx={{fontSize: '12px', color: '#757679', pb: 2}}>
                  Tools can enhance the capabilities of your voicebots, allowing them to execute specific tasks during a
                  call. By integrating these tools, you can seamlessly align your voicebots with your existing
                  workflows.
                </Typography>
                <BotLlmFunctionsField form={form} />
              </Box>
            </>
          )}
          {type === VoiceBotCallbackType.WEBHOOK && (
            <>
              <Box mt={4} width="75%">
                <BotWebhookUrlField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specified URL will be used by the integration to send POST requests with the event payload.
                </Typography>
              </Box>
              <Box mt={3} width="75%">
                <BotWebhookSecretField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * A secret verifies incoming HTTP requests are from the Wildix system.
                </Typography>
              </Box>
              <Alert severity="info" sx={{mt: 3}}>
                The endpoint should be publicly accessible with a valid HTTPS certificate, accept HTTP POST requests
                with JSON payloads, respond with codes from 200 to 299 as quickly as possible, and handle multiple
                identical calls in case of retries due to network or server failures (up to 3 times).
              </Alert>
            </>
          )}
          {type === VoiceBotCallbackType.SQS && (
            <>
              <Box mt={4} width="75%">
                <BotSqsUrlField form={form} />
              </Box>
              <Box mt={3} width="75%">
                <BotSqsKeyField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specify access key id that will be used to sign request to the SQS.
                </Typography>
              </Box>
              <Box mt={3} width="75%">
                <BotSqsSecretField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specify secret access key that will be used to sign request to the SQS.
                </Typography>
              </Box>
            </>
          )}
          {type === VoiceBotCallbackType.DIALOGFLOW_CX && (
            <Box mt={4} width="75%">
              <BotDialogflowCxCredentialsField form={form} />
              <Box mt={3} sx={{display: 'flex', flexDirection: 'row'}}>
                <Box sx={{flex: '0.5', pr: 3}}>
                  <BotDialogflowCxLocationField form={form} />
                </Box>
                <Box sx={{flex: '0.5', pl: 3}}>
                  <BotDialogflowCxLanguageField form={form} />
                </Box>
              </Box>
              <Box mt={3}>
                <BotDialogflowCxAgentField form={form} />
              </Box>
            </Box>
          )}
          {type === VoiceBotCallbackType.OPENAI_ASSISTANT && (
            <Box mt={4} width="75%">
              <BotOpenAiKeyField form={form} />
              <Box mt={3}>
                <BotOpenAiAssistantField form={form} />
              </Box>
            </Box>
          )}
        </Box>

        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 5}}>Advanced Configuration</Typography>

        <Box mt={2}>
          <AdvancedSettingsAccordion title="Interruption Detection">
            <Box sx={{fontSize: '13px'}}>
              This section enables the Interruption Function. When activated, customers can interrupt the bot, and if an
              interruption occurs, the system will stop the playback of the voice bot's response.
            </Box>
            <InterruptionsEnabledField form={form} />
          </AdvancedSettingsAccordion>
          <AdvancedSettingsAccordion title="End Call Function">
            <Box sx={{fontSize: '13px'}}>
              The End Call Function enables the bot to end a call. When this feature is activated and a specific list of
              phrases is provided, the system will automatically hang up if the bot's response includes any of those
              phrases.
            </Box>
            <EndCallEnabledField form={form} />
            <Box mt={3}>
              <EndCallPhrasesField form={form} />
            </Box>
          </AdvancedSettingsAccordion>
          <AdvancedSettingsAccordion title="End Call Triggers">
            <Box>
              <Box sx={{fontSize: '16px', fontWeight: '500'}}>Silence Timeout</Box>
              <Box sx={{fontSize: '13px', pb: 1, pt: 1}}>
                How long to wait before a call is automatically ended due to inactivity.
              </Box>
              <TimeoutSliderField min={10} max={600} defaultValue={30} name="pipeline.silenceTimeout" form={form} />
            </Box>
            <Box sx={{height: '1px', background: 'rgba(0, 0, 0, 0.12)', marginTop: '24px', marginBottom: '24px'}} />
            <Box>
              <Box sx={{fontSize: '16px', fontWeight: '500'}}>Maximum Duration</Box>
              <Box sx={{fontSize: '13px', pb: 1, pt: 1}}>The maximum number of seconds a call will last.</Box>
              <TimeoutSliderField min={10} max={3600} defaultValue={1800} name="pipeline.maximumDuration" form={form} />
            </Box>
          </AdvancedSettingsAccordion>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={6}>
        <CancelButton
          sx={{width: '96px'}}
          disabled={isSubmitting}
          onClick={onCancelClick}
          variant="contained"
          disableElevation
          size="medium">
          Cancel
        </CancelButton>
        <LoadingButton
          loading={isSubmitting}
          sx={{ml: 2, width: '96px'}}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="medium">
          {id ? 'Save' : 'Add'}
        </LoadingButton>
      </Box>
    </form>
  );
}
